import React, { useState } from "react";
import "../App.css";
// import user-custom JSON files
import {
  // PopupWidget,
  PopupModal,
} from "react-calendly";
import userInfo from "../json/account.json";
import serviceInfo from "../json/services.json";
import experienceInfo from "../json/experiences.json";
import skillInfo from "../json/skills.json";
import portfolios from "../json/portfolios.json";
// import components
import Portfolio from "../components/portfolio";

function Root() {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <div className="page new-skin">
      {/* <!-- preloader --> */}
      <div className="preloader">
        <div className="centrize full-width">
          <div className="vertical-center">
            <div className="spinner">
              <div className="double-bounce1" />
              <div className="double-bounce2" />
            </div>
          </div>
        </div>
      </div>

      {/* <!-- background --> */}
      <div className="background gradient">
        <ul className="bg-bubbles">
          <li />
          <li />
          <li />
          <li />
          <li />
          <li />
          <li />
          <li />
          <li />
          <li />
        </ul>
      </div>

      {/* <!--
        Container
      --> */}
      <div
        className="container opened"
        data-animation-in="fadeInLeft"
        data-animation-out="fadeOutLeft"
      >
        {/* <!--
          Header
        --> */}
        <header className="header">
          {/* <!-- header profile --> */}
          <div className="profile">
            <div className="title">{userInfo.account.name}</div>
            <div className="subtitle subtitle-typed">
              <div className="typing-title">
                <p>{userInfo.account.role[0]}</p>
                <p>{userInfo.account.role[1]}</p>
                <p>{userInfo.account.role[2]}</p>
              </div>
            </div>
          </div>

          {/* <!-- menu btn -->
          <!--<a href="#" className="menu-btn"><span></span></a>-->

          <!-- menu --> */}
          <div className="top-menu">
            <ul>
              <li className="active">
                <a href="#about-card">
                  <span className="icon ion-person" />
                  <span className="link">About</span>
                </a>
              </li>
              <li>
                <a href="#resume-card">
                  <span className="icon ion-android-list" />
                  <span className="link">Resume</span>
                </a>
              </li>
              <li>
                <a href="#works-card">
                  <span className="icon ion-paintbrush" />
                  <span className="link">Works</span>
                </a>
              </li>
              {/* <li>
                <a href="#blog-card">
                  <span className="icon ion-chatbox-working"></span>
                  <span className="link">Blog</span>
                </a>
              </li> */}
              <li>
                <a href="#contacts-card">
                  <span className="icon ion-at" />
                  <span className="link">Contact</span>
                </a>
              </li>
            </ul>
          </div>
        </header>

        {/* <!--
          Card - Started
        --> */}
        <div className="card-started" id="home-card">
          {/* <!--
            Profile
          --> */}
          <div className="profile no-photo">
            {/* <!-- profile image --> */}
            <div
              className="slide"
              style={{
                backgroundImage: `url(${userInfo.account.primayAvatarPath})`,
                backgroundSize: "95% 115%",
              }}
            />

            {/* <!-- profile titles --> */}
            <div className="title">{userInfo.account.name}</div>
            {/* <!--<div className="subtitle">Web Designer</div>--> */}
            <div className="subtitle subtitle-typed">
              <div className="typing-title">
                <p>{userInfo.account.role[0]}</p>
                <p>{userInfo.account.role[1]}</p>
                <p>{userInfo.account.role[2]}</p>
              </div>
            </div>

            {/* <!-- profile socials --> */}
            <div className="social">
              {/* github */}
              <a
                target="_blank"
                aria-label="email"
                rel="noreferrer"
                href={`mailto:${userInfo.contact.email}`}
              >
                <span className="fa fa-envelope-open" />
              </a>
              {/* github */}
              <a
                target="_blank"
                aria-label="github"
                rel="noreferrer"
                href={`${userInfo.contact.github}`}
              >
                <span className="fa fa-github" />
              </a>
              {/* linkedin */}
              <a
                target="_blank"
                aria-label="linkedin"
                rel="noreferrer"
                href={`${userInfo.contact.linkedin}`}
              >
                <span className="fa fa-linkedin-square" />
              </a>
              {/* telegram */}
              <a
                target="_blank"
                aria-label="telegram"
                rel="noreferrer"
                href={`${userInfo.contact.telegram}`}
              >
                <span className="fa fa-telegram" />
              </a>
              {/* skype */}
              <a
                target="_blank"
                aria-label="skype"
                rel="noreferrer"
                href={`${userInfo.contact.skype}`}
              >
                <span className="fa fa-skype" />
              </a>
              {/* twitter */}
              {/* <a target="_blank" rel="noreferrer" href={`${userInfo.contact.twitter}`}><span className="fa fa-twitter"></span></a> */}
              {/* stackoverflow */}
              {/* <a target="_blank" href="https://stackoverflow.com/"><span className="fa fa-stack-overflow"></span></a> */}
            </div>

            {/* <!-- profile buttons --> */}
            <div className="lnks">
              <a
                href={`${userInfo.resume.path}`}
                className="lnk"
                target="_blank"
                rel="noreferrer"
              >
                <span className="text">Download CV</span>
                <span className="ion ion-archive" />
              </a>
              <a href="#contacts-card" className="lnk discover">
                <span className="text">Contact Me</span>
                <span className="arrow" />
              </a>
            </div>
          </div>
        </div>

        {/* <!--
          Card - About
        --> */}
        <div className="card-inner animated active" id="about-card">
          <div className="card-wrap">
            {/* <!--
              About
            --> */}
            <div className="content about">
              {/* <!-- title --> */}
              <div className="title">About Me</div>

              {/* <!-- content --> */}
              <div className="row">
                <div className="col col-d-6 col-t-6 col-m-12 border-line-v">
                  <div className="text-box">
                    <p>{userInfo.account.aboutMe}</p>
                  </div>
                </div>
                <div className="col col-d-6 col-t-6 col-m-12 border-line-v">
                  <div className="info-list">
                    <ul>
                      <li>
                        <strong>Age . . . . .</strong> {userInfo.account.age}
                      </li>
                      <li>
                        <strong>Residence . . . . .</strong>{" "}
                        {userInfo.account.country}
                      </li>
                      <li>
                        <strong>Freelance . . . . .</strong>{" "}
                        {userInfo.account.status}
                      </li>
                      <li>
                        <strong>Languages . . . . .</strong>{" "}
                        {userInfo.account.language}
                      </li>
                      {/* <li><strong>Address . . . . .</strong> {userInfo.account.address} </li> */}
                      <li>
                        <strong>Email . . . . .</strong>{" "}
                        {userInfo.contact.email}
                      </li>
                      <li>
                        <strong>Phone . . . . .</strong>{" "}
                        {userInfo.contact.phone}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clear" />
              </div>
            </div>

            {/* <!--
              Services
            --> */}
            <div className="content services">
              {/* <!-- title --> */}
              <div className="title">My Services</div>

              {/* <!-- content --> */}
              <div className="row service-items border-line-v">
                {/* <!-- service item --> */}
                <div className="col col-d-6 col-t-6 col-m-12 border-line-h">
                  <div className="service-item">
                    <div className="icon">
                      <span className="fa fa-code" />
                    </div>
                    <div className="name">
                      <span>{serviceInfo.web.title}</span>
                    </div>
                    <div className="desc">
                      <div>
                        <p>{serviceInfo.web.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- service item --> */}
                <div className="col col-d-6 col-t-6 col-m-12 border-line-h">
                  <div className="service-item">
                    <div className="icon">
                      <span className="fa fa-mobile" />
                    </div>
                    <div className="name">
                      <span> {serviceInfo.mobile.title} </span>
                    </div>
                    <div className="desc">
                      <div>
                        <p> {serviceInfo.mobile.description} </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- service item --> */}
                <div className="col col-d-6 col-t-6 col-m-12 border-line-h">
                  <div className="service-item">
                    <div className="icon">
                      <span className="fa fa-handshake-o" />
                    </div>
                    <div className="name">
                      <span> {serviceInfo.machineLearning.title} </span>
                    </div>
                    <div className="desc">
                      <div>
                        <p> {serviceInfo.machineLearning.description} </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- service item --> */}
                <div className="col col-d-6 col-t-6 col-m-12 border-line-h">
                  <div className="service-item">
                    <div className="icon">
                      <span className="fa fa-bitcoin" />
                    </div>
                    <div className="name">
                      <span> {serviceInfo.blockchain.title} </span>
                    </div>
                    <div className="desc">
                      <div>
                        <p> {serviceInfo.blockchain.description} </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="clear" />
            </div>

            {/* <!--
              Price Tables
            --> */}
            {/* <div className="content pricing">

              <div className="title">Pricing</div>

              <div className="row pricing-items">

                <div className="col col-d-6 col-t-6 col-m-12 border-line-v">
                  <div className="pricing-item">
                    <div className="icon"><i className="fa fa-battery-half"></i></div>
                    <div className="name">Basic</div>
                    <div className="amount">
                      <span className="dollar">$</span>
                      <span className="number">22</span>
                      <span className="period">hour</span>
                    </div>
                    <div className="feature-list">
                      <ul>
                        <li>Web Development</li>
                        <li>Advetising</li>
                        <li>Game Development</li>
                        <li className="disable">Music Writing</li>
                        <li className="disable">Photography <strong>new</strong></li>
                      </ul>
                    </div>
                    <div className="lnks">
                      <a href="#" className="lnk">
                        <span className="text">Buy Basic</span>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col col-d-6 col-t-6 col-m-12 border-line-v">
                  <div className="pricing-item">
                    <div className="icon"><i className="fa fa-battery-full"></i></div>
                    <div className="name">Pro</div>
                    <div className="amount">
                      <span className="dollar">$</span>
                      <span className="number">48</span>
                      <span className="period">hour</span>
                    </div>
                    <div className="feature-list">
                      <ul>
                        <li>Web Development</li>
                        <li>Advetising</li>
                        <li>Game Development</li>
                        <li>Music Writing</li>
                        <li>Photography <strong>new</strong></li>
                      </ul>
                    </div>
                    <div className="lnks">
                      <a href="#" className="lnk">
                        <span className="text">Buy Pro</span>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="clear"></div>
              </div>

            </div> */}

            {/* <!--
              Fun Fact
            --> */}
            {/* <div className="content fuct">

              <div className="title">Fun Fact</div>

              <div className="row fuct-items">

                <div className="col col-d-3 col-t-3 col-m-6 border-line-v">
                  <div className="fuct-item">
                    <div className="icon"><span className="fa fa-music"></span></div>
                    <div className="name">80 Albumes Listened</div>
                  </div>
                </div>

                <div className="col col-d-3 col-t-3 col-m-6 border-line-v">
                  <div className="fuct-item">
                    <div className="icon"><span className="fa fa-trophy"></span></div>
                    <div className="name">15 Awards Won</div>
                  </div>
                </div>

                <div className="col col-d-3 col-t-3 col-m-6 border-line-v">
                  <div className="fuct-item">
                    <div className="icon"><span className="fa fa-coffee"></span></div>
                    <div className="name">1 000 Cups of coffee</div>
                  </div>
                </div>

                <div className="col col-d-3 col-t-3 col-m-6 border-line-v">
                  <div className="fuct-item">
                    <div className="icon"><span className="fa fa-flag"></span></div>
                    <div className="name">10 Countries Visited</div>
                  </div>
                </div>

                <div className="clear"></div>
              </div>

            </div> */}

            {/* <!--
              Clients
            --> */}
            <div className="content clients">
              {/* <!-- title --> */}
              <div className="title">Clients</div>

              {/* <!-- content --> */}
              <div className="row client-items">
                {/* <!-- client item --> */}
                <div className="col col-d-3 col-t-3 col-m-6 border-line-v">
                  <div className="client-item">
                    <div className="image">
                      <a
                        target="_blank"
                        aria-label="avawatz company"
                        rel="noreferrer"
                        href="https://www.avawatz.com"
                      >
                        <img src="images/clients/avawatz.png" alt="" />
                      </a>
                    </div>
                  </div>
                </div>

                {/* <!-- client item --> */}
                <div className="col col-d-3 col-t-3 col-m-6 border-line-v">
                  <div className="client-item">
                    <div className="image">
                      <a
                        target="_blank"
                        aria-label="company"
                        rel="noreferrer"
                        href="https://www.lifeanalytics.org"
                      >
                        <img src="images/clients/lifeanalytics.png" alt="" />
                      </a>
                    </div>
                  </div>
                </div>

                {/* <!-- client item --> */}
                <div className="col col-d-3 col-t-3 col-m-6 border-line-v">
                  <div className="client-item">
                    <div className="image">
                      <a
                        target="_blank"
                        aria-label="company"
                        rel="noreferrer"
                        href="https://www.bigtiny.com.sg/"
                      >
                        <img src="images/clients/bigtiny.png" alt="" />
                      </a>
                    </div>
                  </div>
                </div>

                {/* <!-- client item --> */}
                <div className="col col-d-3 col-t-3 col-m-6 border-line-v">
                  <div className="client-item">
                    <div className="image">
                      <a
                        target="_blank"
                        aria-label="company"
                        rel="noreferrer"
                        href="https://www.iyelo.com/en"
                      >
                        <img src="images/clients/yelo.png" alt="" />
                      </a>
                    </div>
                  </div>
                </div>

                <div className="clear" />
              </div>
            </div>

            {/* <!-- Quote --> */}
            <div className="content quote">
              {/* <!-- title --> */}
              <div className="title">
                <span>Quote</span>
              </div>

              {/* <!-- content --> */}
              <div className="row">
                <div className="col col-d-12 col-t-12 col-m-12 border-line-v">
                  <div className="revs-item">
                    <div className="text">
                      <div> {userInfo.account.phrase} </div>
                    </div>
                    <div className="user">
                      <div className="img">
                        <img
                          src={`${userInfo.account.secondaryAvatarPath}`}
                          alt={`${userInfo.account.name}`}
                        />
                      </div>
                      <div className="info">
                        <div className="name">{userInfo.account.name}</div>
                        <div className="company">
                          {userInfo.account.mainRole}
                        </div>
                      </div>
                      <div className="clear" />
                    </div>
                  </div>
                </div>
                <div className="clear" />
              </div>
            </div>
          </div>
        </div>

        {/* <!--
          Card - Resume
        --> */}
        <div className="card-inner" id="resume-card">
          <div className="card-wrap">
            {/* <!--
              Resume
            --> */}
            <div className="content resume">
              {/* <!-- title --> */}
              <div className="title">Resume</div>

              {/* <!-- content --> */}
              <div className="row">
                {/* <!-- experience --> */}
                <div className="col col-d-6 col-t-6 col-m-12 border-line-v">
                  <div className="resume-title border-line-h">
                    <div className="icon">
                      <i className="fa fa-briefcase" />
                    </div>
                    <div className="name">Experience</div>
                  </div>
                  <div className="resume-items">
                    <div className="resume-item border-line-h active">
                      {/* company 1 */}
                      <div className="date">
                        {experienceInfo.company1.start_date} -{" "}
                        {experienceInfo.company1.end_date}
                      </div>
                      <div className="name">
                        {experienceInfo.company1.title}
                      </div>
                      <div className="company">
                        {experienceInfo.company1.company_name}
                        <span>
                          &nbsp;&nbsp;&nbsp;{" "}
                          {experienceInfo.company1.company_location}
                        </span>
                      </div>
                      <p>{experienceInfo.company1.content}</p>
                    </div>
                    {/* company 2 */}
                    <div className="resume-item border-line-h">
                      <div className="date">
                        {experienceInfo.company2.start_date} -{" "}
                        {experienceInfo.company2.end_date}
                      </div>
                      <div className="name">
                        {experienceInfo.company2.title}
                      </div>
                      <div className="company">
                        {experienceInfo.company2.company_name}
                        <span>
                          &nbsp;&nbsp;&nbsp;{" "}
                          {experienceInfo.company2.company_location}
                        </span>
                      </div>
                      <p>{experienceInfo.company2.content}</p>
                    </div>

                    {/* company 3 */}
                    <div className="resume-item border-line-h">
                      <div className="date">
                        {experienceInfo.company3.start_date} -{" "}
                        {experienceInfo.company3.end_date}
                      </div>
                      <div className="name">
                        {experienceInfo.company3.title}
                      </div>
                      <div className="company">
                        {experienceInfo.company3.company_name}
                        <span>
                          &nbsp;&nbsp;&nbsp;{" "}
                          {experienceInfo.company3.company_location}
                        </span>
                      </div>
                      <p>{experienceInfo.company3.content}</p>
                    </div>
                    {/* company 4 */}
                    <div className="resume-item">
                      <div className="date">
                        {experienceInfo.company4.start_date} -{" "}
                        {experienceInfo.company4.end_date}
                      </div>
                      <div className="name">
                        {experienceInfo.company4.title}
                      </div>
                      <div className="company">
                        {experienceInfo.company4.company_name}
                        <span>
                          &nbsp;&nbsp;&nbsp;{" "}
                          {experienceInfo.company4.company_location}
                        </span>
                      </div>
                      <p>{experienceInfo.company4.content}</p>
                    </div>
                  </div>
                </div>

                {/* <!-- education --> */}
                <div className="col col-d-6 col-t-6 col-m-12 border-line-v">
                  <div className="resume-title border-line-h">
                    <div className="icon">
                      <i className="fa fa-university" />
                    </div>
                    <div className="name">Education</div>
                  </div>
                  <div className="resume-items">
                    <div className="resume-item border-line-h">
                      <div className="date">
                        {experienceInfo.education1.start_date} -{" "}
                        {experienceInfo.education1.end_date}
                      </div>
                      <div className="name">
                        {experienceInfo.education1.school_name}
                      </div>
                      <div className="company">
                        {experienceInfo.education1.school_location}
                      </div>
                      <p>{experienceInfo.education1.content}</p>
                    </div>
                    <div className="resume-item border-line-h">
                      <div className="date">
                        {experienceInfo.education2.start_date} -{" "}
                        {experienceInfo.education1.end_date}
                      </div>
                      <div className="name">
                        {experienceInfo.education2.school_name}
                      </div>
                      <div className="company">
                        {experienceInfo.education2.school_location}
                      </div>
                      <p>{experienceInfo.education3.content}</p>
                    </div>
                    <div className="resume-item">
                      <div className="date">
                        {experienceInfo.education3.start_date} -{" "}
                        {experienceInfo.education1.end_date}
                      </div>
                      <div className="name">
                        {experienceInfo.education3.school_name}
                      </div>
                      <div className="company">
                        {experienceInfo.education3.school_location}
                      </div>
                      <p>{experienceInfo.education3.content}</p>
                    </div>
                  </div>
                </div>

                <div className="clear" />
              </div>
            </div>

            {/* <!--
              Skills
            --> */}
            <div className="content skills">
              {/* <!-- title --> */}
              <div className="title">My Skills</div>

              {/* <!-- content --> */}
              <div className="row">
                {/* <!-- skill item --> */}
                <div className="col col-d-6 col-t-6 col-m-12 border-line-v">
                  {/* Frontend Skills */}
                  <div className="skills-list">
                    <div className="skill-title border-line-h">
                      <div className="icon">
                        <i className="fa fa-bullseye" />
                      </div>
                      <div className="name">Frontend</div>
                    </div>
                    <ul>
                      {skillInfo.frontend.skills.map((el, ind) => (
                        <li
                          className={
                            ind + 1 < skillInfo.frontend.skills.length
                              ? "border-line-h"
                              : ""
                          }
                          key={`skill-${el}`}
                        >
                          <div className="name">
                            {skillInfo.frontend.skills[ind]}
                          </div>
                          <div className="progress">
                            <div
                              className="percentage"
                              style={{ width: skillInfo.frontend.marks[ind] }}
                            />
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                {/* <!-- skill item --> */}
                <div className="col col-d-6 col-t-6 col-m-12 border-line-v">
                  {/* Backend Skills */}
                  <div className="skills-list">
                    <div className="skill-title border-line-h">
                      <div className="icon">
                        <i className="fa fa-server" />
                      </div>
                      <div className="name">Backend</div>
                    </div>
                    <ul>
                      {skillInfo.backend.skills.map((el, ind) => (
                        <li
                          className={
                            ind + 1 < skillInfo.backend.skills.length
                              ? "border-line-h"
                              : ""
                          }
                          key={`skill-${el}`}
                        >
                          <div className="name">
                            {skillInfo.backend.skills[ind]}
                          </div>
                          <div className="progress">
                            <div
                              className="percentage"
                              style={{ width: skillInfo.backend.marks[ind] }}
                            />
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                {/* <!-- skill item --> */}
                <div className="col col-d-6 col-t-6 col-m-12 border-line-v">
                  <div className="skills-list circles">
                    <div className="skill-title border-line-h">
                      <div className="icon">
                        <i className="fa fa-mobile-phone" />
                      </div>
                      <div className="name">Mobile</div>
                    </div>
                    <ul>
                      {skillInfo.mobile.skills.map((skill, ind) => (
                        <li key={`mobile-skill-${skill}`}>
                          <div className="name">{skill}</div>
                          <div
                            className={`progress p${skillInfo.mobile.marks[ind]}`}
                          >
                            <span>{skillInfo.mobile.marks[ind]}%</span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                {/* <!-- skill item --> */}
                <div className="col col-d-6 col-t-6 col-m-12 border-line-v">
                  <div className="skills-list circles">
                    <div className="skill-title border-line-h">
                      <div className="icon">
                        <i className="fa fa-brands fa-bitcoin" />
                      </div>
                      <div className="name">blockchain</div>
                    </div>
                    <ul>
                      {skillInfo.blockchain.skills.map((skill, ind) => (
                        <li key={`blockchain-skill-${skill}`}>
                          <div className="name">{skill}</div>
                          <div
                            className={`progress p${skillInfo.blockchain.marks[ind]}`}
                          >
                            <span>{skillInfo.blockchain.marks[ind]}%</span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                {/* <!-- skill item --> */}
                <div className="col col-d-6 col-t-6 col-m-12 border-line-v">
                  <div className="skills-list list">
                    <div className="skill-title border-line-h">
                      <div className="icon">
                        <i className="fa fa-list" />
                      </div>
                      <div className="name">Knowledge & Tools</div>
                    </div>
                    <ul>
                      {skillInfo.knowledges.map((kw) => (
                        <li>
                          <div className="name" key={`kw-${kw}`}>
                            {kw}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                {/* <!-- skill item --> */}
                <div className="col col-d-6 col-t-6 col-m-12 border-line-v">
                  <div className="skills-list dotted">
                    <div className="skill-title border-line-h">
                      <div className="icon">
                        <i className="fa fa-flag" />
                      </div>
                      <div className="name">Languages</div>
                    </div>
                    <ul>
                      {skillInfo.languages.names.map((el, ind) => (
                        <li
                          className={
                            ind + 1 < skillInfo.languages.names.length
                              ? "border-line-h"
                              : ""
                          }
                          key={`language-${el}`}
                        >
                          <div className="name">
                            {skillInfo.languages.names[ind]}
                          </div>
                          <div className="progress">
                            <div
                              className="percentage"
                              style={{
                                width: skillInfo.languages.marks[ind],
                              }}
                            />
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="clear" />
              </div>
            </div>

            {/* <!--
              Testimonials
            --> */}
            <div className="content testimonials">
              {/* <!-- title --> */}
              <div className="title">Testimonials</div>

              {/* <!-- content --> */}
              <div className="row testimonials-items">
                {/* <!-- client item --> */}
                <div className="col col-d-12 col-t-12 col-m-12 border-line-v">
                  <div className="revs-carousel default-revs">
                    <div className="owl-carousel">
                      {skillInfo.testimonials.map((client) => (
                        <div className="item" key={`feedback-${client}`}>
                          <div className="revs-item">
                            <div className="text">{client.feedback}</div>
                            <div className="user">
                              <div className="img">
                                <img src={client.avatarPath} alt="" />
                              </div>
                              <div className="info">
                                <div className="name">
                                  {client.name}&nbsp;&nbsp;&nbsp;(
                                  {client.country})
                                </div>
                                <div className="company">
                                  {client.role}&nbsp;&nbsp;&nbsp;(
                                  {client.date})
                                </div>
                              </div>
                              <div className="clear" />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="clear" />
              </div>
            </div>
          </div>
        </div>

        {/* <!--
          Card - Works
        --> */}
        <div className="card-inner" id="works-card">
          <div className="card-wrap">
            {/* <!--
              Works
            --> */}
            <div className="content works">
              {/* <!-- title --> */}
              <div className="title">Recent Works</div>

              {/* <!-- filters --> */}
              <div className="filter-menu filter-button-group">
                <div className="f_btn active">
                  <label htmlFor="all">
                    <input type="radio" name="fl_radio" value="grid-item" />
                    All
                  </label>
                </div>
                <div className="f_btn">
                  <label htmlFor="frontend">
                    <input type="radio" name="fl_radio" value="frontend" />
                    Frontend
                  </label>
                </div>
                <div className="f_btn">
                  <label htmlFor="backend">
                    <input type="radio" name="fl_radio" value="backend" />
                    Backend
                  </label>
                </div>
                {/* <div className="f_btn">
                  <label><input type="radio" name="fl_radio" value="video" />ML Learning</label>
                </div> */}
                <div className="f_btn">
                  <label htmlFor="mobile">
                    <input type="radio" name="fl_radio" value="mobile" />
                    Mobile
                  </label>
                </div>
                {/* <div className="f_btn">
                  <label><input type="radio" name="fl_radio" value="design" />blockchain</label>
                </div> */}
              </div>

              {/* <!-- content --> */}
              <div className="row grid-items border-line-v">
                {/* <!-- work item video --> */}
                {/* <div className="col col-d-6 col-t-6 col-m-12 grid-item video border-line-h">
                  <div className="box-item">
                    <div className="image">
                      <a href="https://vimeo.com/97102654" className="has-popup-video">
                        <img src="images/works/work2.jpg" alt="" />
                        <span className="info">
                          <span className="ion ion-videocamera"></span>
                        </span>
                      </a>
                    </div>
                    <div className="desc">
                      <a href="https://vimeo.com/97102654" className="name has-popup-video">Minimalism Shapes</a>
                      <div className="category">Video</div>
                    </div>
                  </div>
                </div> */}

                {/* <!-- work item music --> */}
                {/* <div className="col col-d-6 col-t-6 col-m-12 grid-item music border-line-h">
                  <div className="box-item">
                    <div className="image">
                      <a href="https://w.soundcloud.com/player/?visual=true&url=http%3A%2F%2Fapi.soundcloud.com%2Ftracks%2F221650664&show_artwork=true" className="has-popup-music">
                        <img src="images/works/work3.jpg" alt="" />
                        <span className="info">
                          <span className="ion ion-music-note"></span>
                        </span>
                      </a>
                    </div>
                    <div className="desc">
                      <a href="#" className="name has-popup">Staircase</a>
                      <div className="category">Music</div>
                    </div>
                  </div>
                </div> */}

                {/* <!-- work item design --> */}
                {portfolios.map((portfolio) => (
                  <div
                    key={`portfolio-${portfolio}`}
                    className={`col col-d-6 col-t-6 col-m-12 grid-item ${portfolio.category} border-line-h`}
                  >
                    <Portfolio
                      key={`portfolio-${portfolio}`}
                      portfolio={portfolio}
                    />
                  </div>
                ))}

                {/* <!-- work item photo --> */}
                {/* <div className="col col-d-6 col-t-6 col-m-12 grid-item gallery border-line-h">
                  <div className="box-item">
                    <div className="image">
                      <a href="#gallery-1" className="has-popup-gallery">
                        <img src="images/works/work5.jpg" alt="" />
                        <span className="info">
                          <span className="ion ion-images"></span>
                        </span>
                      </a>
                      <div id="gallery-1" className="mfp-hide">
                        <a href="images/works/work6.jpg"></a>
                        <a href="images/works/work1.jpg"></a>
                        <a href="images/works/work5.jpg"></a>
                      </div>
                    </div>
                    <div className="desc">
                      <a href="images/works/work5.jpg" className="name has-popup-image">Gereal Travels</a>
                      <div className="category">Gallery</div>
                    </div>
                  </div>
                </div> */}

                {/* <!-- work item music --> */}
                {/* <div className="col col-d-6 col-t-6 col-m-12 grid-item music border-line-h">
                  <div className="box-item">
                    <div className="image">
                      <a href="https://w.soundcloud.com/player/?visual=true&url=http%3A%2F%2Fapi.soundcloud.com%2Ftracks%2F221650664&show_artwork=true" className="has-popup-music">
                        <img src="images/works/work8.jpg" alt="" />
                        <span className="info">
                          <span className="ion ion-music-note"></span>
                        </span>
                      </a>
                    </div>
                    <div className="desc">
                      <a href="#" className="name has-popup">Daylight Entrance</a>
                      <div className="category">Music</div>
                    </div>
                  </div>
                </div> */}

                {/* <!-- work item design --> */}
                {/* <div className="col col-d-6 col-t-6 col-m-12 grid-item design border-line-h">
                  <div className="box-item">
                    <div className="image">
                      <a href="#popup-2" className="has-popup-media">
                        <img src="images/works/work7.jpg" alt="" />
                        <span className="info">
                          <span className="ion ion-images"></span>
                        </span>
                      </a>
                    </div>
                    <div className="desc">
                      <a href="#popup-2" className="name has-popup-media">Social Website</a>
                      <div className="category">Content</div>
                    </div>
                    <div id="popup-2" className="popup-box mfp-fade mfp-hide">
                      <div className="content">
                        <div className="image">
                          <img src="images/works/work7.jpg" alt=""/>
                        </div>
                        <div className="desc">
                          <div className="post-box">
                            <h1>Mobile Application</h1>
                            <div className="blog-detail">Design</div>
                            <div className="blog-content">
                              <p>
                                So striking at of to welcomed resolved. Northward by described up household therefore
                                attention. Excellence decisively nay man yet impression for contrasted remarkably.
                              </p>
                              <p>
                                Forfeited you engrossed but gay sometimes explained. Another as studied it to evident.
                                Merry sense given he be arise. Conduct at an replied removal an amongst. Remaining
                                determine few her two cordially admitting old.
                              </p>
                              <blockquote>
                                Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia
                                Curae; Pellentesque suscipit.
                              </blockquote>
                              <p>
                                Tiled say decay spoil now walls meant house. My mr interest thoughts screened of outweigh
                                removing. Evening society musical besides inhabit ye my. Lose hill well up will he over on.
                                Increasing sufficient everything men him admiration unpleasing sex.
                              </p>
                              <ul className="list-style">
                                <li>Greatest properly off ham exercise all.</li>
                                <li>Unsatiable invitation its possession nor off.</li>
                                <li>All difficulty estimating unreserved increasing the solicitude.</li>
                              </ul>
                              <p>
                                Unpleasant astonished an diminution up partiality. Noisy an their of meant. Death means
                                up civil do an offer wound of.
                              </p>
                            </div>
                            <a href="#" className="button">
                              <span className="text">View Project</span>
                              <span className="arrow"></span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                <div className="clear" />
              </div>
            </div>
          </div>
        </div>

        {/* <!--
          Card - Blog
        --> */}
        {/* <div className="card-inner blog" id="blog-card">
          <div className="card-wrap">

            <div className="content blog">

              <div className="title">
                <span >Blog</span>
              </div>

              <div className="row border-line-v">

                <div className="col col-d-6 col-t-6 col-m-12">
                  <div className="box-item">
                    <div className="image">
                      <a href="blog-post-new.html">
                        <img src="images/blog/blog1.jpg" alt="By spite about do of allow" />
                        <span className="info">
                          <span className="ion ion-document-text"></span>
                        </span>
                      </a>
                    </div>
                    <div className="desc">
                      <a href="blog-post-new.html">
                        <span className="date">April 28, 2020</span>
                      </a>
                      <a href="blog-post-new.html" className="name">By spite about do of allow</a>
                      <div className="text">
                        <p>Ex audire suavitate has, ei quodsi tacimates sapientem sed, pri zril ubique ut. Te cule tation munere noluisse. Enim torquatos&#8230;</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col col-d-6 col-t-6 col-m-12">
                  <div className="box-item">
                    <div className="image">
                      <a href="blog-post-new.html">
                        <img src="images/blog/blog2.jpg" alt="By spite about do of allow" />
                        <span className="info">
                          <span className="ion ion-document-text"></span>
                        </span>
                      </a>
                    </div>
                    <div className="desc">
                      <a href="blog-post-new.html">
                        <span className="date">April 28, 2020</span>
                      </a>
                      <a href="blog-post-new.html" className="name">By spite about do of allow</a>
                      <div className="text">
                        <p>Ex audire suavitate has, ei quodsi tacimates sapientem sed, pri zril ubique ut. Te cule tation munere noluisse. Enim torquatos&#8230;</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col col-d-6 col-t-6 col-m-12">
                  <div className="box-item">
                    <div className="image">
                      <a href="blog-post-new.html">
                        <img src="images/blog/blog3.jpg" alt="By spite about do of allow" />
                        <span className="info">
                          <span className="ion ion-document-text"></span>
                        </span>
                      </a>
                    </div>
                    <div className="desc">
                      <a href="blog-post-new.html">
                        <span className="date">April 28, 2020</span>
                      </a>
                      <a href="blog-post-new.html" className="name">By spite about do of allow</a>
                      <div className="text">
                        <p>Ex audire suavitate has, ei quodsi tacimates sapientem sed, pri zril ubique ut. Te cule tation munere noluisse. Enim torquatos&#8230;</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col col-d-6 col-t-6 col-m-12">
                  <div className="box-item">
                    <div className="image">
                      <a href="blog-post-new.html">
                        <img src="images/blog/blog1.jpg" alt="By spite about do of allow" />
                        <span className="info">
                          <span className="ion ion-document-text"></span>
                        </span>
                      </a>
                    </div>
                    <div className="desc">
                      <a href="blog-post-new.html">
                        <span className="date">April 28, 2020</span>
                      </a>
                      <a href="blog-post-new.html" className="name">By spite about do of allow</a>
                      <div className="text">
                        <p>Ex audire suavitate has, ei quodsi tacimates sapientem sed, pri zril ubique ut. Te cule tation munere noluisse. Enim torquatos&#8230;</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clear"></div>
              </div>

              <div className="pager">
                <nav className="navigation pagination">
                  <div className="nav-links">
                    <span className="page-numbers current">1</span>
                    <a className="page-numbers" href="#">2</a>
                    <a className="next page-numbers" href="#">Next</a>
                  </div>
                </nav>
              </div>

            </div>

          </div>
        </div> */}

        {/* <!--
          Card - Contacts
        --> */}
        <div className="card-inner contacts" id="contacts-card">
          <div className="card-wrap">
            {/* <!--
              Conacts Info
            --> */}
            <div className="content contacts">
              {/* <!-- title --> */}
              <div className="title">Get in Touch</div>

              {/* <!-- content --> */}
              <div className="row">
                <div className="col col-d-12 col-t-12 col-m-12 border-line-v">
                  {/* <div className="map" id="map"></div> */}
                  <div className="info-list">
                    <ul>
                      <li>
                        <strong>Address . . . . .</strong>{" "}
                        <a
                          href="https://www.google.com/maps/dir//43+St+Martin's+Dr,+Singapore/@1.2993566,103.8233708,14.73z/data=!4m9!4m8!1m0!1m5!1m1!1s0x31da198afcfaf0bb:0x52a8ea0fcf9dedae!2m2!1d103.8242761!2d1.3059291!3e0?entry=ttu"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {userInfo.account.address}, {userInfo.account.country}
                        </a>
                      </li>
                      <li>
                        <strong>Email . . . . .</strong>{" "}
                        <a
                          href={`mailto:${userInfo.contact.email}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {userInfo.contact.email}
                        </a>
                      </li>
                      <li>
                        <strong>Phone . . . . .</strong>{" "}
                        {userInfo.contact.phone}
                      </li>
                      <li>
                        <strong>Freelance . . . . .</strong>{" "}
                        {userInfo.account.status}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clear" />
              </div>
            </div>

            {/* <!--
              Contact Form
            --> */}
            <div className="content contacts">
              {/* <!-- title --> */}
              <div className="title">Contact Us</div>
              <div className="row">
                <div
                  className="col col-d-12 col-t-12 col-m-12 border-line-v"
                  style={{
                    backgroundImage: `url("images/handshake004.jpg")`,
                    backgroundSize: "cover",
                  }}
                >
                  {/* <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', textAlign: 'center',}}>
                          <div className="dropStroke dropTextShadow" style={{ fontSize: '32px', padding: '12px 12px', backgroundColor: 'rgba(255,255,255,0.9)', borderRadius: '24px'}}>
                              Let’s Start the Conversation
                          </div>
                      </div> */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <div
                      className="water-content"
                      style={{ padding: "12px", margin: "12px" }}
                    >
                      <h2
                        style={{
                          backgroundColor: "rgba(255,255,255,0.95)",
                          borderRadius: "24px",
                        }}
                      >
                        Let’s&nbsp;Start&nbsp;Conversation
                      </h2>
                      {/* <h2>Let’s Start Conversation</h2> */}
                      <h2
                        style={{
                          backgroundColor: "rgba(255,255,255,0.95)",
                          borderRadius: "24px",
                        }}
                      >
                        Let’s&nbsp;Start&nbsp;Conversation
                      </h2>
                    </div>
                  </div>
                  <h2
                    className="dropStroke glowText"
                    style={{ textAlign: "center", marginTop: "24px" }}
                  >
                    GRAB SOME TIME
                  </h2>
                  <div
                    className="col col-d-12 col-t-12 col-m-12 border-line-v"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      className="book-btn"
                      type="submit"
                      onClick={() => {
                        setModalOpen(true);
                      }}
                    >
                      <svg
                        height="24"
                        width="24"
                        fill="#FFFFFF"
                        viewBox="0 0 24 24"
                        data-name="Layer 1"
                        id="Layer_1"
                        className="book-sparkle"
                      >
                        <path d="M10,21.236,6.755,14.745.264,11.5,6.755,8.255,10,1.764l3.245,6.491L19.736,11.5l-6.491,3.245ZM18,21l1.5,3L21,21l3-1.5L21,18l-1.5-3L18,18l-3,1.5ZM19.333,4.667,20.5,7l1.167-2.333L24,3.5,21.667,2.333,20.5,0,19.333,2.333,17,3.5Z" />
                      </svg>

                      <span className="book-text">Book Your Call</span>
                    </button>
                  </div>
                </div>
                {/* <div className="col col-d-6 col-t-6 col-m-6 border-line-v">
                  </div> */}
                {/* <PopupWidget
                      url="https://calendly.com/devsirius44/together-with-you"
                      rootElement={document.getElementById("root")}
                      text="Click here to schedule!"
                      textColor="#ffffff"
                      color="#00a2ff"
                  /> */}
              </div>
              <PopupModal
                url="https://calendly.com/devsirius44/together-with-you"
                // pageSettings={this.props.pageSettings}
                // utm={this.props.utm}
                // prefill={this.props.prefill}
                onModalClose={() => setModalOpen(false)}
                open={isModalOpen}
                /*
                 * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                 * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                 */
                rootElement={document.getElementById("root")}
              />
              {/* <!-- content --> */}
              {/* <div className="row">
                <div className="col col-d-12 col-t-12 col-m-12 border-line-v">
                  <div className="contact_form">
                    <form id="cform" method="post">
                      <div className="row">
                        <div className="col col-d-6 col-t-6 col-m-12">
                          <div className="group-val">
                            <input type="text" name="name" placeholder="Full Name" />
                          </div>
                        </div>
                        <div className="col col-d-6 col-t-6 col-m-12">
                          <div className="group-val">
                            <input type="text" name="email" placeholder="Email Address" />
                          </div>
                        </div>
                        <div className="col col-d-12 col-t-12 col-m-12">
                          <div className="group-val">
                            <textarea name="message" placeholder="Your Message"></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="align-left">
                        <button>
                          <span className="text">Send Message&nbsp;&nbsp;&nbsp;</span>
                          <span className="fa fa-arrow-circle-right" style={{fontSize: "120%"}}></span>
                        </button>
                      </div>
                    </form>
                    <div className="alert-success">
                      <p>Thanks, your message is sent successfully.</p>
                    </div>
                  </div>
                </div>
                <div className="clear"></div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="s_overlay" />
      {/* <div className="content-sidebar">
        <div className="sidebar-wrap search-form">
          <aside id="secondary" className="widget-area">
            <section id="search-2" className="widget widget_search">
              <label>
                <span className="screen-reader-text">Search for:</span>
                <input type="search" className="search-field" placeholder="Search …" value="" name="s"/>
              </label>
              <input type="submit" className="search-submit" value="Search"/>
            </section>
            <section className="widget widget_recent_entries">
              <h2 className="widget-title">
                <span className="widget-title-span"><span className="first-word">Recent</span> Posts</span>
              </h2>
              <ul>
                <li>
                  <a href="#">Creativity Is More Than</a>
                </li>
                <li>
                  <a href="#">Designing the perfect</a>
                </li>
                <li>
                  <a href="#">Music Player Design</a>
                </li>
                <li>
                  <a href="#">A Song And Dance Act</a>
                </li>
                <li>
                  <a href="#">By spite about do of allow</a>
                </li>
              </ul>
            </section>
            <section className="widget widget_recent_comments">
              <h2 className="widget-title">
                <span className="widget-title-span"><span className="first-word">Recent</span> Comments</span>
              </h2>
              <ul>
                <li className="recentcomments">
                  <span className="comment-author-link">JOHN SMITH</span> on <a href="#">Creativity Is More Than</a>
                </li>
                <li className="recentcomments">
                  <span className="comment-author-link">ADAM SMITH</span> on <a href="#">Creativity Is More Than</a>
                </li>
                <li className="recentcomments">
                  <span className="comment-author-link">admin</span> on <a href="#">Designing the perfect</a>
                </li>
                <li className="recentcomments">
                  <span className="comment-author-link">admin</span> on <a href="#">Designing the perfect</a>
                </li>
                <li className="recentcomments">
                  <span className="comment-author-link">James</span> on <a href="#">Designing the perfect</a>
                </li>
              </ul>
            </section>
            <section className="widget widget_archive">
              <h2 className="widget-title">
                <span className="widget-title-span">
                  <span className="first-letter">Archives</span>
                </span>
              </h2>
              <ul>
                <li>
                  <a href="#">November 2018</a>
                </li>
              </ul>
            </section>
            <section className="widget widget_categories">
              <h2 className="widget-title">
                <span className="widget-title-span"><span className="first-letter">Categories</span></span>
              </h2>
              <ul>
                <li className="cat-item cat-item-2">
                  <a href="#">Design</a>
                </li>
                <li className="cat-item cat-item-3">
                  <a href="#">Music</a>
                </li>
              </ul>
            </section>
            <section className="widget widget_meta">
              <h2 className="widget-title">
                <span className="widget-title-span"><span className="first-letter">Meta</span></span>
              </h2>
              <ul>
                <li><a href="#">Log in</a></li>
                <li><a href="#">Entries feed</a></li>
                <li><a href="#">Comments feed</a></li>
                <li><a href="#">WordPress.org</a></li>
              </ul>
            </section>
          </aside>
        </div>
        <span className="close"></span>
      </div> */}
    </div>
  );
}

export default Root;
